<template>
  <div class="md-layout text-center">
    <div class="md-layout-item md-size-33 md-medium-size-50 md-small-size-70 md-xsmall-size-100">
      <modal-warning-reset-password ref="warningRessetPasswordModal"></modal-warning-reset-password>
      <ValidationObserver ref="form" v-slot="{ handleSubmit }">
        <form class="md-layout lims-form form-login" @submit.prevent="handleSubmit()">
          <modal-log-out ref="logoutModal"></modal-log-out>
          <login-card header-color="primary">
            <h4 slot="title" class="title">{{ $t('pages/auth/login/title.logIn') }}</h4>
            <div slot="inputs">
              <lims-field :model="formData" :schema="schema" field="username" viewMode="custom">
                <md-icon slot="icon">account_circle</md-icon>
                <md-input
                  slot="field"
                  v-model="formData.username"
                  type="text"
                  maxlength="250"
                  @change="$lowercaseAndTrimSpace(formData, 'username')"
                  @keyup.enter="enterToLogin"
                  autocomplete="off"
                  :disabled="passwordAttemptExceeded()"
                ></md-input>
              </lims-field>

              <lims-field :model="formData" :schema="schema" field="password" viewMode="custom">
                <md-icon slot="icon">lock</md-icon>
                <md-input
                  slot="field"
                  v-model="formData.password"
                  @keyup.enter="enterToLogin"
                  type="password"
                  maxlength="250"
                  autocomplete="new-password"
                  :disabled="passwordAttemptExceeded()"
                ></md-input>
              </lims-field>
            </div>
            <p slot="remember-me" class="remember-me">
              <md-checkbox v-model="formData.rememberMe" class="lims-checkbox" :disabled="passwordAttemptExceeded()">{{
                $t('pages/auth/login/label.rememberMe')
              }}</md-checkbox>
            </p>
            <div slot="count-down-to-login-again">
              <p v-if="passwordAttemptExceeded()" class="danger-color">
                {{ $t('pages/auth/login/label.passwordAttemptExceeded') }}
                <span v-if="countDownTimeInFormByMinute > 0"
                  >{{ countDownTimeInFormByMinute }} {{ $t('pages/auth/login/label.minutes') }}
                </span>
                <span>{{ countDownTimeInFormBySecond }} {{ $t('pages/auth/login/label.seconds') }}</span>
              </p>
            </div>
            <p slot="actions">
              <md-button
                @click="doLogin"
                class="md-primary"
                :disabled="!formData.username || !formData.password || passwordAttemptExceeded()"
              >
                {{ $t('pages/auth/login/button.signIn') }}
              </md-button>
            </p>
            <p slot="forgot-password">
              <span class="text-underline" @click="onClickForgotPassword">{{
                $t('pages/auth/login/label.forgotPassword')
              }}</span>
            </p>
          </login-card>
        </form>
      </ValidationObserver>
    </div>
  </div>
</template>
<script>
import { LoginCard } from '@/components';
import { mapActions, mapState } from 'vuex';
import { AuthService } from '@/services';
import LimsField from '@/components/Lims/LimsField.vue';
import ModalWarningResetPassword from '@/components/Lims/modals/ModalWarningResetPassword.vue';
import { APP_ROUTES, CHALLENGE_NAMES, LOCAL_STORAGE_KEYS } from '@/core/constants';
import { FormMixins } from '@/core/mixins';
import AuthMixins from '@/pages/Auth/auth.mixins';
import ModalLogOut from '@/components/Lims/modals/ModalLogout';
import authService from '@/services/auth.service';
import { AUTH_LOGIN_ERROR, SERVER_ERROR_CODE, VERIFY_SMS_ERROR } from '@/core/error-constants';
import { deleteCookie, getCookie } from '@/core/helpers';

export default {
  components: {
    ModalLogOut,
    LoginCard,
    LimsField,
    ModalWarningResetPassword,
  },

  mixins: [FormMixins, AuthMixins],

  props: {
    redirectTo: {
      type: String,
      require: false,
      default: '',
    },
  },
  created() {
    this.CHECK_USER_COOKIE();
  },
  mounted() {
    if (this.showLogoutModal) {
      this.$refs.logoutModal.open();
    }
    this.autoFillUsername();
  },

  data() {
    return {
      formData: {
        rememberMe: false,
        username: null,
        password: null,
      },
      countDownTimeInForm: 0,
      countDownTimeInFormByMinute: 0,
      countDownTimeInFormBySecond: 0,
    };
  },

  computed: {
    ...mapState('auth', {
      isLogged: (state) => state.isLogged,
      showLogoutModal: (state) => state.showLogoutModal,
      countDownTime: (state) => state.countDownTime,
      login: (state) => state.login,
    }),

    schema() {
      return {
        entity: 'auth/login',
        fields: {
          username: 'required',
          password: 'required',
        },
      };
    },
  },

  watch: {
    isLogged: {
      handler: function (isLogged) {
        if (isLogged) {
          const redirectTo = this.redirectTo || APP_ROUTES.DASHBOARD;
          this.$router.push(redirectTo);
        } else {
          if (getCookie(LOCAL_STORAGE_KEYS.SESSION_EXPIRED)) {
            this.$refs.warningRessetPasswordModal.open();
            deleteCookie(LOCAL_STORAGE_KEYS.SESSION_EXPIRED);
            setTimeout(() => {
              this.$refs.warningRessetPasswordModal.close();
            }, 10 * 1000); // auto close after 10 seconds
          }
        }
      },
      deep: true,
    },

    countDownTime() {
      this.countDownTimeInForm = this.countDownTime;
    },

    countDownTimeInForm() {
      if (this.countDownTimeInForm >= 0) {
        setTimeout(() => {
          this.countDownTimeInFormByMinute = Math.floor(this.countDownTimeInForm / 60);
          this.countDownTimeInFormBySecond = this.countDownTimeInForm - this.countDownTimeInFormByMinute * 60;
          if (this.countDownTimeInFormBySecond === 0 && this.countDownTimeInFormByMinute > 0) {
            this.countDownTimeInFormBySecond = 60;
            this.countDownTimeInFormByMinute -= 1;
          }
          this.countDownTimeInForm--;
        }, 1000);
      } else {
        this.countDownTimeInFormBySecond = 0;
        this.$store.dispatch('auth/UPDATE_COUNT_DOWN_TIME', {
          countDownTime: 0,
        });
      }
    },
  },

  methods: {
    ...mapActions('auth', ['CHECK_USER_COOKIE']),
    autoFillUsername() {
      const username = this.$getAuthMixins().getRememberLoginId();
      if (username) {
        this.formData.username = username;
        this.formData.rememberMe = true;
      }
    },
    doLogin: function () {
      this.$refs.form.validate().then(async (isFormValid) => {
        try {
          if (!isFormValid) {
            throw new Error(this.$t('pages/auth/login/message:login_required'));
          }
          const { username, password, rememberMe } = this.formData;

          const { data, error } = await this.submitLoginForm({
            username,
            password,
          });

          this.throwErrorIfExisted({ error, data });

          this.handleLoginResponseData(data);

          this.checkRememberMe({ username, rememberMe });
        } catch (error) {
          this.$alertError(error.toString());
        }
      });
    },

    async submitLoginForm({ username, password }) {
      const { data, error } = await AuthService.login({
        username,
        password,
      });

      return { data, error };
    },

    throwErrorIfExisted({ error, data }) {
      if (!error) {
        return;
      }
      // After the 6th consecutive failed login attempt, user must wait for an amount
      // of time. And this number comes from the API
      if (data.countdownTime) {
        this.startCountDownTimer(data.countdownTime);

        throw new Error(error);
      }
      // Note: other special cases should be placed here

      if (error === VERIFY_SMS_ERROR.MaxSendAttemptsReached) {
        throw this.$alertError(this.$t('page/auth/CompleteYourAccount/error/waitTenMinutes'));
      }

      if (error === AUTH_LOGIN_ERROR.NOT_EXIST_USER) {
        throw this.$alertError(this.$t('pages/auth/login/message:login_notExistUser'));
      }

      if (error === SERVER_ERROR_CODE.NotFound) {
        throw this.$alertError(this.$t('global/errorServerSide/NotFound'));
      }

      if (error === VERIFY_SMS_ERROR.RecipientNotValid) {
        throw this.$alertError(this.$t('global/errorServerSide/RecipientNotValid'));
      }

      throw this.$alertError(error);
    },

    handleLoginResponseData(data) {
      // challengeName can be "EMAIL_MFA,SMS_MFA,APP_MFA,POLICY"
      const isChallengeMode = data.challengeName && data.sessionId;
      const isReviewPolicyMode = isChallengeMode && data.challengeName === CHALLENGE_NAMES.POLICY;
      const is2FAMode = isChallengeMode && data.challengeName !== CHALLENGE_NAMES.POLICY;
      if (is2FAMode) {
        this.open2FAScreen(data);
        return;
      }
      if (isReviewPolicyMode) {
        this.openReviewPolicyScreen(data);
        return;
      }
      // otherwise
      this.$getAuthMixins().saveLoginState(this.$store, data);
    },

    async startCountDownTimer(countDownTime) {
      await this.$store.dispatch('auth/UPDATE_COUNT_DOWN_TIME', {
        countDownTime,
      });
    },

    open2FAScreen(data) {
      this.$store.dispatch('auth/UPDATE_CHALLENGE_NAME', {
        challengeName: data.challengeName,
        sessionId: data.sessionId,
        isSetupSms: data.isSetupSms,
        isSetupTotp: data.isSetupTotp,
        email: data.email,
        phoneNumber: data.phoneNumber,
      });
      Promise.resolve(this.$router.push(APP_ROUTES.AUTH_LOGIN_VERIFY_TWO_FA));
    },

    openReviewPolicyScreen(data) {
      // SAVE STATE FOR POLICY LIST
      authService.saveReviewPolicyState(this.$store, data);
      Promise.resolve(this.$router.push(`${APP_ROUTES.AUTH_REVIEW_POLICY}`));
    },

    checkRememberMe({ rememberMe, username }) {
      this.$getAuthMixins().toggleRememberMode({ rememberMe, loginId: username });
    },

    saveLoggedInUserData() {},

    enterToLogin() {
      if (this.formData.username && this.formData.password) {
        this.doLogin();
      }
    },

    onClickForgotPassword() {
      this.$router.push(APP_ROUTES.AUTH_LOGIN_FORGOT_PASSWORD);
    },

    passwordAttemptExceeded() {
      return this.countDownTimeInForm >= 0 && this.countDownTimeInFormBySecond > 0;
    },
  },
};
</script>
<style src="./AuthLogin.scss" lang="scss" scoped></style>
