<template>
  <md-dialog :md-close-on-esc="true" :md-active.sync="isVisible" @md-clicked-outside="close" class="lims-modal">
    <div class="dialog-icon-close" @click="close"><md-icon>close</md-icon></div>
    <div class="icon-update"><md-icon>lock</md-icon></div>
    <md-dialog-title>{{ $t('component/lims/modals/logout/dialogTitle') }}</md-dialog-title>
    <div class="lims-form dialog-content">
      <p class="text-center">{{ $t('component/lims/modals/logout/dialogContent') }}</p>
    </div>
    <md-dialog-actions>
      <md-button @click="close" class="lims-form-button">
        {{ $t('global/button/button.ok') }}
      </md-button>
    </md-dialog-actions>
  </md-dialog>
</template>

<script>
import modalMixins from './modal.mixins';
export default {
  name: 'modal-log-out',
  mixins: [modalMixins],
};
</script>

<style lang="scss">
.icon-update {
  padding-top: 20px;
  text-align: center;
}
</style>
